import { useApolloClient } from '@apollo/client';
import { EntityRow, EntityValue, useEntities, useLang, formatDateString, ContentNode, CellLabel, DataTable, EntityLinkValue, ContentTitle, formatNumber, DataTableNameValue, MainAside, Video, CitationCell, EntityMultiValueList } from '@shapeable/core';
import { RestorationProject, IndicatorValue } from '@shapeable/kelp-forest-alliance-types';
import { breakpoints, theme } from '@shapeable/theme';
import { Classable, HasChildren, Indicator, MainAsideDetailsLayout } from '@shapeable/types';
import { filter, sortBy } from 'lodash';
import * as React from 'react'; // tslint:disable-line
import styled, { css } from 'styled-components';
import { IndicatorValueTable } from './indicator-value-table';


// -------- Types -------->

export type RestorationProjectDetailsLayoutProps = MainAsideDetailsLayout & {
  entity?: RestorationProject;
}

export const RestorationProjectDetailsLayoutDefaultProps: RestorationProjectDetailsLayoutProps = {
};

// -------- Styles -------->

const MainAsideStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(15)};
    
  `,
});

const AsideStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)};
  `,
});

const ObservationAsideStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)} ${theme.UNIT(4)};
  `,
});


const AsideItemStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    margin-bottom: ${theme.UNIT(4)};
    padding-bottom: ${theme.UNIT(4)};
  `,
});

const ContactStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});


const CountryStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});


const ContainerStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    width: 100%;
  `,
});

const MainOrganisationStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});

const DescriptionStyles = breakpoints({

});


const ContentStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)} ${theme.UNIT(5)};
    ${theme.H_DOT_BG_END()};
  `,
});

const ObjectiveStyles = breakpoints({
  base: css`
    ${ContentStyles};
    ${theme.H_DOT_BG_END()};
  `,
});

const CauseOfDeclineStyles = breakpoints({
  base: css`
    ${ContentStyles};
    ${theme.H_DOT_BG_END()};
  `,
});

const ObservationsStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    margin: ${theme.UNIT(4)} 0 0;
    padding: ${theme.UNIT(4)};
    background: ${theme.COLOR('bright-warm')};
  `,
});

const ObservationStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    width: 100%;
    box-sizing: border-box;
    border-top: ${theme.COLOR('line-mid')} 1px solid;
    padding: ${theme.UNIT(3)} ${theme.UNIT(0)} ${theme.UNIT(5)};

    &:first-child {
      border-top: none;
    }
  `,
});

const ObservationMainAsideStyles = breakpoints({
  base: css`
  `,
});

const ActionSummaryStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    padding: ${theme.UNIT(4)} 0;
  `,
});

const LessonsLearnedStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    padding: ${theme.UNIT(4)} 0;
  `,
});

const ProjectImpactsStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    padding: ${theme.UNIT(4)} 0;
  `,
});

const DisturbanceDescriptionStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    padding: ${theme.UNIT(4)} 0;
  `,
});


const CellHeaderStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(1)};
  `,
});

const ReasonsStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)};
  `,
});


const EcologicalPrincipleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    width: 100%;
    box-sizing: border-box;
    .shp-entity-row__description {
      padding-top: ${theme.UNIT(1)};
    }
    margin-top: ${theme.UNIT(2.5)};
    padding-top: ${theme.UNIT(2.5)};
    ${theme.H_DOT_BG()};

    h2 {
      line-height: 1.2em;
    }
  `,
});


const EcologicalPrinciplesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(6)} 0  ${theme.UNIT(2)};
  `,
});

const SpeciesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
    margin-right: ${theme.UNIT(2)};
  `,
});

const MethodologiesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
    margin-right: ${theme.UNIT(2)};
  `,
});

const ImpactTypesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
    margin-right: ${theme.UNIT(2)};
  `,
});


const DisturbanceTypesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
    margin-right: ${theme.UNIT(2)};
  `,
});

const MotivationsStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
  `,
});

const ValuesStyles = breakpoints({
  base: css`
    padding: 0 0 ${theme.UNIT(2)};
  `,
});

const TableStyles = breakpoints({
  base: css`
    width: 100%;
    max-width: 800px;

  `,
});

const DataTableStyles = breakpoints({
  base: css`
    ${TableStyles};
    td {
      padding-right: ${theme.UNIT(3)};
    }
    tbody {
      padding-right: 0;
    }
  `,
});


const OrganisationsStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} ${theme.UNIT(3)} ${theme.UNIT(4)};
  `,
});

const OrganisationStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    width: 66.67%;
    padding-bottom: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const ListStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const DataStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)} 0 0;
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const TransplantAdherenceMethodStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(2)};
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const TransplantSourceStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(2)};
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const TransplantLifeStageStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(2)};
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const VideoStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(3)};
  `,
});

const CitationStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG()};
    font-family: ${theme.FONT('sans')};
    width: 100%;
    box-sizing: border-box;
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)} ${theme.UNIT(5)};

    h2 {
      line-height: 1.4em;
    }
  `,
});


const CitationCellStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(1)} 0 0;
  `,
});

const ObservationReasonsStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0;
  `,
});

const ObservationCitationStyles = breakpoints({
  base: css`
    ${CitationStyles};
    margin-top: ${theme.UNIT(4)};
    border-bottom: 1px solid ${theme.COLOR('line-mid')};
  `,
});
const ObservationCauseOfDeclineStyles = breakpoints({
  base: css`
    ${CauseOfDeclineStyles};
    padding-left: 0;
    padding-right: 0;
  `,
});

const ValueStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});




// -------- Components -------->

const My = {
  
  MainAside: styled(MainAside)`${MainAsideStyles}`,

  Citation: styled(EntityRow)`${CitationStyles}`,
    CitationCell: styled(CitationCell)`${CitationCellStyles}`,

  ObservationCitation: styled.div`${ObservationCitationStyles}`,
    
    ObservationAside: styled.div`${ObservationAsideStyles}`,

  Value: styled(EntityValue)`${ValueStyles}`,

  Aside: styled.div`${AsideStyles}`,
    Country: styled(EntityLinkValue)`${CountryStyles}`,
    MainOrganisation: styled(EntityLinkValue)`${MainOrganisationStyles}`,
    Contact: styled(EntityLinkValue)`${ContactStyles}`,

  Container: styled.div<RestorationProjectDetailsLayoutProps>`${ContainerStyles}`,

    CellHeader: styled(CellLabel)`${CellHeaderStyles}`,

    Title: styled(ContentTitle)`${TitleStyles}`,
    Content: styled(ContentNode)`${ContentStyles}`,
    
    List: styled.div`${ListStyles}`,

    Description: styled(ContentNode)`${DescriptionStyles}`,
    Objective: styled(ContentNode)`${ObjectiveStyles}`,

    Organisations: styled.div`${OrganisationsStyles}`,
      Organisation: styled(EntityRow)`${OrganisationStyles}`,

    Observations: styled.div`${ObservationsStyles}`,

      ObservationMainAside: styled(MainAside)`${ObservationMainAsideStyles}`,

      Observation: styled(EntityRow)`${ObservationStyles}`,

      ActionSummary: styled(ContentNode)`${ActionSummaryStyles}`,
      LessonsLearned: styled(ContentNode)`${LessonsLearnedStyles}`,
      ProjectImpacts: styled(ContentNode)`${ProjectImpactsStyles}`,
      DisturbanceDescription: styled(ContentNode)`${DisturbanceDescriptionStyles}`,

      EcologicalPrinciples: styled.div`${EcologicalPrinciplesStyles}`,
        EcologicalPrinciple: styled(EntityRow)`${EcologicalPrincipleStyles}`,

      Species: styled(EntityMultiValueList)`${SpeciesStyles}`,
      Methodologies: styled(EntityMultiValueList)`${MethodologiesStyles}`,
      ImpactTypes: styled(EntityMultiValueList)`${ImpactTypesStyles}`,
      DisturbanceTypes: styled(EntityMultiValueList)`${DisturbanceTypesStyles}`,
      
      
      TransplantAdherenceMethod: styled.div`${TransplantAdherenceMethodStyles}`,
      TransplantSource: styled.div`${TransplantSourceStyles}`,
      TransplantLifeStage: styled.div`${TransplantLifeStageStyles}`,

      Motivations: styled(EntityMultiValueList)`${MotivationsStyles}`,

      Values: styled.div`${ValuesStyles}`,
        Table: styled(IndicatorValueTable)`${TableStyles}`,

    CauseOfDecline: styled(ContentNode)`${CauseOfDeclineStyles}`,
    Reasons: styled(EntityMultiValueList)`${ReasonsStyles}`,
    
    ObservationCauseOfDecline: styled(ContentNode)`${ObservationCauseOfDeclineStyles}`,
    ObservationReasons: styled(EntityMultiValueList)`${ObservationReasonsStyles}`,
    
    Data: styled.div`${DataStyles}`, 

    TransplantInfo: styled(DataTable)`${DataTableStyles}`,
    Costs: styled(DataTable)`${DataTableStyles}`,

    Video: styled(Video)`${VideoStyles}`,
};

export const RestorationProjectDetailsLayout: React.FC<RestorationProjectDetailsLayoutProps> = (props) => {
  const { className, entity, banner } = props;
  const { 
    observations = [], reasons = [], causeOfDecline, organisation, description, 
    objective, organisations = [], motivations = [], country, contact,
    video, citation, 
  } = entity;

  const entities = useEntities();
  const hasOrganisations = !!organisations.length;
  const hasReasons = !!reasons.length;
  const t = useLang();

  return (
    <>
    {banner}
    <My.MainAside
      aside={
        <My.Aside>
        {
          contact && <My.Contact label="Primary Contact" entity={contact} />
        }
        {
          organisation && <My.MainOrganisation label="Primary Organisation" entity={organisation} />
        }
        {
          country && <My.Country entity={country} />
        }
        <My.Motivations items={motivations} />
        {
          video && <My.Video entity={video} />
        }
        </My.Aside>
      }>
      <My.Container className={className}>

      <My.Objective title={t('Restoration Objective:')} entity={objective} />
      <My.Content title={t('Selection Criteria:')} entity={description} />

      {
      causeOfDecline && 
      <My.CauseOfDecline title={t('Cause Of Decline:')} entity={causeOfDecline} />
      }

      <My.Reasons items={reasons} />

      {
        citation &&
        <My.Citation variant="default" entity={citation}>
          <My.CitationCell urlMaxLength={60} showName={false} isExtended entity={citation} />
        </My.Citation>
      }

      </My.Container>
    </My.MainAside>
      
    <My.Observations>
      <My.Title>{t('Site Observations')}:</My.Title>
        <div>
        {
        observations.map(observation => {
          const dates = filter([
            observation.startDate && formatDateString(observation.startDate, "Do MMM YYYY"),
            observation.endDate && formatDateString(observation.endDate, "Do MMM YYYY"),
          ]);
          
          const {
            species = [], methodologies = [], reasons = [], impactTypes = [], actionSummary, lessonsLearned, projectImpacts, indicatorValues = [], ecologicalPrinciples = [],
            transplantAdherenceMethod, transplantLifeStage, transplantSource, recordedBy, disturbanceTypes = [], disturbanceDescription, causeOfDecline, 
            areaOfRestoration, areaOfProtection, successful, 
            distanceToNearestKelpForestInMetres, totalCost, capitalCost, planningCost, constructionCost, maintenanceCost, inKindCost, monitoringCost, costYear, costCurrency = 'USD', citation, motivations = [],
          } = observation;

          const hasValues = !!indicatorValues.length;
          const hasEcologicalPrinciples = !!ecologicalPrinciples.length;

          const hasTransplantAdherenceMethod = !!transplantAdherenceMethod;
          const hasTransplantLifeStage = !!transplantLifeStage;
          const hasTransplantSource = !!transplantSource;

          const transplantInfo: DataTableNameValue[] = filter([ 
            hasTransplantAdherenceMethod && {
              name: "Adherence Method",
              value: transplantAdherenceMethod.name,
            },
            hasTransplantLifeStage && {
              name: "Life Stage",
              value: transplantLifeStage.name,
            },
            hasTransplantSource && {
              name: "Source",
              value: transplantSource.name,
            },
          ]);
          
          const costs: DataTableNameValue[] = filter([ 
            costYear && {
              name: "Cost Year",
              value: costYear,
            },
            costCurrency && {
              name: "Cost Currency",
              value: costCurrency,
            },
            totalCost && {
              name: "Total Cost",
              value: formatNumber(totalCost),
            },
            capitalCost && {
              name: "Capital Cost",
              value: formatNumber(capitalCost),
            },
            planningCost && {
              name: "Planning Cost",
              value: formatNumber(planningCost),
            },
            constructionCost && {
              name: "Construction Cost",
              value: formatNumber(planningCost),
            },
            inKindCost && {
              name: "In-Kind Cost",
              value: formatNumber(inKindCost),
            },
            monitoringCost && {
              name: "Monitoring Cost",
              value: formatNumber(monitoringCost),
            },
            maintenanceCost && {
              name: "Maintenance Cost",
              value: formatNumber(maintenanceCost),
            },

            

          ]);

          const hasCosts = !!costs.length;
          const hasTransplantInfo = !!transplantInfo.length;
          
          const areaOfRestorationIndicator: Indicator = !!areaOfRestoration && {
            id: `area-of-restoration-${observation.id}`,
            name: formatNumber(areaOfRestoration, 'en-US', '–', { useGrouping: true, maximumFractionDigits: areaOfRestoration < 1 ? 5 : 0 }),
            __typename: 'Indicator',
          };

          const areaOfProtectionIndicator: Indicator = !!areaOfProtection && {
            id: `area-of-protection-${observation.id}`,
            name: formatNumber(areaOfProtection, 'en-US', '–', { useGrouping: true, maximumFractionDigits: areaOfProtection < 1 ? 5 : 0 }),
            __typename: 'Indicator',
          };

          const distanceToNearestKelpForestInMetresIndicator: Indicator = !!distanceToNearestKelpForestInMetres && {
            id: `distance-to-nearest-kelp-forest-in-metres-${observation.id}`,
            name: formatNumber(distanceToNearestKelpForestInMetres),
            __typename: 'Indicator',
          };

          return <My.Observation showMenu label="Observation Date" key={observation.id} name={(dates.join(' – '))} showDescription entity={observation}>

            <My.ObservationMainAside
              aside={
              <My.ObservationAside>

              {
                recordedBy.map(person => (
                  <My.Contact key={person.id} label="Recorded By" entity={person} />
                ))
              }

              <My.Species items={species} background="#FFF" />
              <My.Motivations background="#FFF" items={motivations} />
              <My.ImpactTypes background="#FFF" items={impactTypes} />
              <My.Methodologies background="#FFF" items={methodologies} />
              <My.DisturbanceTypes background="#FFF" items={disturbanceTypes} />
              {
                hasEcologicalPrinciples && 
                <My.EcologicalPrinciples>
                <My.CellHeader>{entities.EcologicalPrinciple.pluralLabel}:</My.CellHeader>
                {
                  sortBy(ecologicalPrinciples, ec => ec.number).map(ec => {
                    return (
                      <My.EcologicalPrinciple key={ec.id} variant="smallName" entity={ec} />
                    );
                  })
                }
                </My.EcologicalPrinciples>
              }
              </My.ObservationAside>
              }
            >
              <My.ActionSummary title={t('Action Summary:')} entity={actionSummary} />
              <My.LessonsLearned title={t('Lessons Learned:')} entity={lessonsLearned} />
              <My.ProjectImpacts title={t('Project Impacts:')} entity={projectImpacts} />
              <My.DisturbanceDescription title={t('Nature of Disturbance:')} entity={disturbanceDescription} />

              {
                causeOfDecline && 
                <My.ObservationCauseOfDecline entity={causeOfDecline} />
              }

              {
                !!reasons.length &&
                <My.ObservationReasons background="#FFF" items={reasons} />
              }

              {
                citation && citation.id !== entity.citation?.id &&
                <My.ObservationCitation>
                  <My.CellHeader>{t("Citation")}:</My.CellHeader>
                  <My.CitationCell urlMaxLength={60} isExtended entity={citation} />
                </My.ObservationCitation>
              }

              {
                areaOfRestorationIndicator && 
                <My.Value variant='card' label="Area of Restoration (Ha)" entity={areaOfRestorationIndicator} />
              }

              {
                areaOfProtectionIndicator && 
                <My.Value variant='card' label="Area of Protection (Ha)" entity={areaOfProtectionIndicator} />
              }

              {
                distanceToNearestKelpForestInMetresIndicator && 
                <My.Value variant='card' label="Distance to nearest kelp forest (in metres)" entity={distanceToNearestKelpForestInMetresIndicator} />
              }

              <My.Data>
                <My.Title>{t('Indicator Data:')}</My.Title>

              {
                hasValues && 
                <My.Values>
                  <My.Table items={indicatorValues} />
                </My.Values>
              }
              
              {
                hasTransplantInfo &&
                <My.TransplantInfo title={"Transplant Info"} values={transplantInfo} cellSpacing={0} />
              }

              {
                hasCosts &&
                <My.Costs title={"Costings"} values={costs} cellSpacing={0} />
              }
              </My.Data>
            </My.ObservationMainAside>
          </My.Observation>
        }
        )
        }
        </div>
      </My.Observations>

      {
      hasOrganisations &&
      <My.Organisations>
        <My.Title>{organisations.length === 1 ? entities.Organisation.label : entities.Organisation.pluralLabel}:</My.Title>
        {
          organisations.map(org => <My.Organisation useLinks showDescription key={org.id} showLabel={false} variant="large" subtitle={org.country && org.country.name} entity={org} />)
        }
      </My.Organisations>
      }

      </>
  );
};

RestorationProjectDetailsLayout.defaultProps = RestorationProjectDetailsLayoutDefaultProps;
